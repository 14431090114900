import "./CancellationAndRefund.css";
const CancellationAndRefund = () => {
  return (
    <div id="cancellationAndRefund_container">
      <h2>Cancellation and Refunds</h2>
      <p>No Cancellation and Refunds are issued.</p>
    </div>
  );
};
export default CancellationAndRefund;
