import React from 'react'
import Poojas from '../../components/Poojas/Poojas'

const Sevas = () => {
  return (
    <div>
      <Poojas/>
    </div>
  )
}

export default Sevas