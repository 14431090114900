import "./pageNotFound.css";
const PageNotFound = () => {
  return (
    <div id="PageNotFound_Container">
      <div>
        <h5>Ooops!</h5>
        <h1 style={{ fontSize: "8rem" }}>404</h1>
        <h5>Page not found!</h5>
      </div>
    </div>
  );
};
export default PageNotFound;
